
import './Error.scss';
import {Button} from "antd";
import {useEffect} from "react";


export const Error = ({match}:any) => {
    useEffect(() => {
        if(match.params.token)
            localStorage.setItem('token', match.params.token)
    })
    const {REACT_APP_SITE_HOME_LINK} = process.env;
    const redirectToPage = (page:any) => {
        // @ts-ignore
        window.location = page
    }

    return (
        <section style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection: "column"}} className="home">
            <h1 style={{margin:"0 auto",textAlign:"center", fontSize: "6rem"}}>404</h1>
            <p style={{margin:"20px auto",textAlign:"center", fontWeight:100, fontSize: "2rem"}}>Așa pagină nu exista</p>
            <Button onClick={() => redirectToPage(REACT_APP_SITE_HOME_LINK)} style={{margin: "0 auto"}} type={"primary"}>Pagina principală</Button>
        </section>
    );
};



