import axios from "../../utils/axios";
import {message} from 'antd'
import {
    getAllUsers,
    getUser,
    getUserConversations,
    getUserConversationMessages,
    setIsApplicant,
    sendMessage,
    deleteConversation,
    deleteMessage,
    editMessage,
    messageEditMode,
    setIsUploadFileMessage,
    searchDialogs,
    setUploadedFiles
} from "../reducers/user";
import socket from "../../utils/socketio";
const {REACT_APP_API_HOST} = process.env

export const getAllUsersAction =  (page:number) => {
    return async (dispatch:any, getState:any) => {
        const result = await axios.post(REACT_APP_API_HOST + `/api/user/getAllUsers?page=${page}`, {count_per_page : 10})
        dispatch(getAllUsers(result.data.success.data))
    }
}

export const getAuthUser =  () => {
    return async (dispatch:any, getState:any) => {
        const result = await axios.post(REACT_APP_API_HOST + `/api/user`)
        dispatch(getUser(result.data.success))
    }
}

export const getUserConversationsAction = () => {
    return async (dispatch:any, getState:any) => {
        const result = await axios.post(REACT_APP_API_HOST + `/api/chat/getApplicantConversations`)
        dispatch(getUserConversations(result.data.success))
    }
}

export const getUserConversationMessagesAction = (key:any,id:any) => {
    return async (dispatch:any, getState:any) => {
        const result = await axios.post(REACT_APP_API_HOST + `/api/chat/getMessagesByConversation`, {id: id})
        console.log(result)
        const action = {id: key, result: result.data.success}
        dispatch(getUserConversationMessages(action))
    }
}

export const setIsApplicantAction = (value:boolean) => {
    return async (dispatch:any, getState:any) => {
        dispatch(setIsApplicant(value))
    }
}
export const getOrganisationConversationsAction = () => {
    return async (dispatch:any, getState:any) => {
        const result = await axios.post(REACT_APP_API_HOST + `/api/chat/getOrganisationConversations`)
        dispatch(getUserConversations(result.data.success))
    }
}

export const sendMessageAction = (context:any) => {
    return async (dispatch:any, getState:any) => {
        const state = getState();
        console.log(state.users.currentConversation,state.users.user.id,message)
        let result:any = {};
        if(context.is_audio){
            result = await axios.post(REACT_APP_API_HOST + `/api/chat/sendMessage`, context.formData)
        }else if(context.is_file){
            console.log(context.formData.get('message'))
            result = await axios.post(REACT_APP_API_HOST + `/api/chat/sendMessage`, context.formData,{headers: {'Content-Type': 'multipart/form-data'}})
        }else{
            result = await axios.post(REACT_APP_API_HOST + `/api/chat/sendMessage`, {application_id: state.users.currentConversationId, user_id: state.users.user.id, message: `${context.message}`})
        }
        socket.emit('sendMessage', { conversation: state.users.currentConversationId, message: result.data.success})

    }
}

export const deleteConversationAction = () => {
    return async (dispatch:any, getState:any) => {
        const state = getState();
        axios.post(REACT_APP_API_HOST + `/api/chat/deleteConversation`, {application_id: state.users.currentConversationId})
            .then((result) => {
                dispatch(deleteConversation(result.data.success))
                message.success('Conversatia a fost ștersă cu success!');
            })
            .catch(error => {
                message.success('Ups! A fost o eroare! Încercați încă o dată!');
            })

    }
}

export const deleteMessageAction = (id:any) => {
    return async (dispatch:any, getState:any) => {
        axios.post(REACT_APP_API_HOST + `/api/chat/deleteMessage`, {message_id: id})
            .then((result) => {
                dispatch(deleteMessage(id))
                message.success('Mesajul a fost șters cu success!');
            })
            .catch(error => {
                message.success('Ups! A fost o eroare! Încercați încă o dată!');
            })

    }
}

export const editMessageAction = (id:any, text:any) => {
    return async (dispatch:any, getState:any) => {
         axios.post(REACT_APP_API_HOST + `/api/chat/editMessage`, {message_id: id, text: `${text}`})
            .then((result) => {
                dispatch(editMessage({id, text}))
                message.success('Mesajul a fost editat cu success!');
            })
            .catch(error => {
                message.success('Ups! A fost o eroare! Încercați încă o dată!');
            })

    }
}



export const messageEditModeAction = (id:any, state:any) => {
    return (dispatch:any, getState:any) => {
        dispatch(messageEditMode({id, state}))
    }
}

export const setIsUploadFileMessageAction = (value:boolean) => {
  return (dispatch:any, getState:any) => {
      dispatch(setIsUploadFileMessage(value))
  }
}


export const searchDialogAction = (value:string) => {
    return (dispatch:any, getState:any) => {
        dispatch(searchDialogs(value))
    }
}

export const setUploadedFilesAction = (value:any) => {
    return (dispatch:any, getState:any) => {
        dispatch(setUploadedFiles(value))
    }
}

export const setMessagesIsReaded = (key:any, id:any) => {
    return async (dispatch:any, getState:any) => {
        await axios.post(REACT_APP_API_HOST + `/api/chat/setMessagesIsReaded`, {application_id: id})
        const result = await axios.post(REACT_APP_API_HOST + `/api/chat/getMessagesByConversation`, {id: id})
        console.log(result)
        const action = {id: key, result: result.data.success}
        dispatch(getUserConversationMessages(action))
    }
}