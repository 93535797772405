import React, {useEffect} from "react";
import "./Dialogs.scss";
import {Avatar} from "antd";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import moment from "moment";
import 'moment/locale/ro'
import {getUserConversationMessagesAction, setMessagesIsReaded} from "../../redux/actions/user";
import {setCurrentConversationId} from "../../redux/reducers/user";
import socket from "../../utils/socketio";

const Dialogs = ({item, index, lastMessage}:any) => {
    const dispatch = useAppDispatch();
    const currentConversation:any = useAppSelector(state => state.users.currentConversation)
    const isAplicant:any = useAppSelector(state => state.users.isApplicant)
    const conversation:any = useAppSelector(state => state.users.conversations[index])
    const {REACT_APP_API_HOST} = process.env
    useEffect(() => {
        dispatch(getUserConversationMessagesAction(index, item.id))
        socket.emit('join', {conversation: item.id})
    },[])

    const setConversation = (key:any, id:any) => {
        dispatch(setCurrentConversationId({key,id}));
        dispatch(setMessagesIsReaded(key, id))
        localStorage.setItem(`unreadMessageCount${item.id}`,"0")
        if(window.innerWidth < 1140) {
            // @ts-ignore
            document.getElementsByClassName('chat__dialog').item(0).setAttribute('style', "display: block")
            // @ts-ignore
            document.getElementsByClassName('chat__sidebar').item(0).setAttribute('style', "display: none")
        }
    }
    return (
        <div key={index}>
                    <div onClick={() => setConversation(index, item.id)}>
                        <div
                            className={classNames('dialogs__item', {
                                'dialogs__item--online': true,
                                'dialogs__item--selected': currentConversation == index,
                            })}>
                            <div className="dialogs__item-avatar">
                                {isAplicant ? (
                                    <Avatar src={`${REACT_APP_API_HOST}/storage/organisationavatars/` + (item.org_avatar != null ? item.org_avatar : "default.jpg")}/>
                                ):(
                                    <Avatar src={`${REACT_APP_API_HOST}/storage/cvavatars/` + (item.cv_avatar != null ? item.cv_avatar : "default.jpg")}/>
                                )
                                }

                            </div>
                            {isAplicant ? (
                                <div className="dialogs__item-info">
                                    <div className="dialogs__item-info-top">
                                        <b>{item.organisation_name}</b>
                                        <span>{moment(item.created_at).locale("ro").fromNow()}</span>
                                    </div>
                                    <div className="dialogs__item-info-bottom">
                                        <p>{item.job_title}</p>
                                        {localStorage.getItem(`unreadMessageCount${item.id}`) !== "0" && (
                                            <div className="dialogs__item-info-bottom-count">
                                                {localStorage.getItem(`unreadMessageCount${item.id}`)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ):(
                                <div className="dialogs__item-info">
                                    <div className="dialogs__item-info-top">
                                        <b>{item.full_name}</b>
                                        <span>{moment(item.created_at).locale("ro").fromNow()}</span>
                                    </div>
                                    <div className="dialogs__item-info-bottom">
                                        <p>{item.job_title}</p>
                                        {localStorage.getItem(`unreadMessageCount${item.id}`) !== "0" && (
                                            <div className="dialogs__item-info-bottom-count">
                                                {localStorage.getItem(`unreadMessageCount${item.id}`)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
        </div>
    );
}


export default Dialogs;
