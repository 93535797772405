import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, Popover } from 'antd';

import './Status.scss';
import {ArrowLeftOutlined, BackwardFilled, EllipsisOutlined} from "@ant-design/icons";
import {deleteConversationAction} from "../../redux/actions/user";
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import {setCurrentConversation, setCurrentConversationId} from "../../redux/reducers/user";

const Status = ({ online }:any) => {
    const dispatch = useAppDispatch();
    const currentConversation = useAppSelector(state => state.users.currentConversation != null ? state.users.currentConversation : -1)
    const user:any = useAppSelector(state => state.users.user)
    const deleteConversation = () => {
        dispatch(deleteConversationAction())
    }

    const goBack = () => {
        dispatch(setCurrentConversation(null))
        if(window.innerWidth < 1140) {
            // @ts-ignore
            document.getElementsByClassName('chat__sidebar').item(0).setAttribute('style', "display: block")
            // @ts-ignore
            document.getElementsByClassName('chat__dialog').item(0).setAttribute('style', "display: none")
        }
    }
    return (
        <div className="chat__dialog-header">
            <div className="chat__dialog-header-center">
                <a href={'https://recrut.md'} className="chat__dialog-header-username"><svg xmlns="http://www.w3.org/2000/svg" width="99.322" height="37.745" viewBox="0 0 99.322 37.745">
                    <base href="https://recrut.md"></base>
                    <linearGradient id="lineargradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#1c7ec6"></stop>
                        <stop offset="1" stop-color="#81caff"></stop>
                    </linearGradient>
                    <linearGradient id="lineargradient2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#81caff"></stop>
                        <stop offset="1" stop-color="#3595db"></stop>
                    </linearGradient>
                    <g id="Group_22" data-name="Group 22" transform="translate(-431.037 -1094.358)">
                        <path id="Path_26" data-name="Path 26" d="M1286.954,1389.016l-.721,1.778a.376.376,0,0,1-.529.24,3.049,3.049,0,0,0-.985-.168,2.4,2.4,0,0,0-2.45,2.547v6.246a.34.34,0,0,1-.36.36h-2.451a.34.34,0,0,1-.36-.36v-11.051a.34.34,0,0,1,.36-.36h2.33a.354.354,0,0,1,.36.36l.1,1.3a3.332,3.332,0,0,1,3-1.922,2.5,2.5,0,0,1,1.586.553A.36.36,0,0,1,1286.954,1389.016Z" transform="translate(-808.605 -279.964)" fill="#171c39"></path>
                        <path id="Path_27" data-name="Path 27" d="M1453.473,1395.31h-7.544c.312,1.562,1.513,2.45,3.363,2.45a4.044,4.044,0,0,0,2.5-.817c.216-.144.36-.144.528.024l1.057,1.033c.168.168.168.336-.024.528a6.134,6.134,0,0,1-4.372,1.8,6.177,6.177,0,0,1-.264-12.348,5.6,5.6,0,0,1,5.6,5.91C1454.313,1394.83,1454.193,1395.31,1453.473,1395.31Zm-7.592-2.066h5.622a2.713,2.713,0,0,0-2.763-2.571A2.814,2.814,0,0,0,1445.881,1393.244Z" transform="translate(-964.674 -279.964)" fill="#171c39"></path>
                        <path id="Path_28" data-name="Path 28" d="M1717.314,1396.343a.34.34,0,0,1,.528,0l1.466,1.393a.348.348,0,0,1,.024.5,6.117,6.117,0,1,1-4.613-10.258,5.984,5.984,0,0,1,4.541,2.09.348.348,0,0,1-.024.5l-1.465,1.417c-.168.168-.336.144-.528-.024a3.458,3.458,0,0,0-2.451-1.057,3.251,3.251,0,0,0,0,6.486A3.543,3.543,0,0,0,1717.314,1396.343Z" transform="translate(-1218.233 -279.964)" fill="#171c39"></path>
                        <path id="Path_29" data-name="Path 29" d="M1972.68,1389.016l-.721,1.778a.376.376,0,0,1-.528.24,3.05,3.05,0,0,0-.985-.168,2.4,2.4,0,0,0-2.45,2.547v6.246a.341.341,0,0,1-.36.36h-2.45a.34.34,0,0,1-.36-.36v-11.051a.34.34,0,0,1,.36-.36h2.33a.354.354,0,0,1,.36.36l.1,1.3a3.332,3.332,0,0,1,3-1.922,2.5,2.5,0,0,1,1.586.553A.36.36,0,0,1,1972.68,1389.016Z" transform="translate(-1462.428 -279.964)" fill="#171c39"></path>
                        <path id="Path_30" data-name="Path 30" d="M2155.661,1394.024v11.051a.34.34,0,0,1-.36.36h-2.354a.354.354,0,0,1-.36-.36l-.1-1.321a4.315,4.315,0,0,1-3.508,1.994c-2.643,0-4.468-1.826-4.468-4.973v-6.751a.34.34,0,0,1,.36-.36h2.45a.34.34,0,0,1,.36.36v6.174c0,1.946,1.129,2.619,2.258,2.619a2.5,2.5,0,0,0,2.546-2.282v-6.511a.34.34,0,0,1,.36-.36h2.45A.34.34,0,0,1,2155.661,1394.024Z" transform="translate(-1633.758 -285.381)" fill="#171c39"></path>
                        <path id="Path_31" data-name="Path 31" d="M2417.179,1320.172v1.874a.341.341,0,0,1-.361.36h-1.682v8.817a.34.34,0,0,1-.36.36h-2.45a.34.34,0,0,1-.36-.36v-8.817h-1.682a.341.341,0,0,1-.36-.36v-1.874a.34.34,0,0,1,.36-.36h1.682v-3.243a.34.34,0,0,1,.36-.36h2.45a.34.34,0,0,1,.36.36v3.243h1.682A.341.341,0,0,1,2417.179,1320.172Z" transform="translate(-1886.82 -211.529)" fill="#171c39"></path>
                        <path id="Path_2" data-name="Path 2" d="M431.037,1521.418l.375-1.739h18.033l8.739,17.957Z" transform="translate(0 -405.533)" fill="url(#lineargradient)"></path>
                        <path id="Union_1" data-name="Union 1" d="M435.779,1094.358h16.474c5.219,0,8.5,4.41,7.331,9.861l0,.016-.384,1.782c-1.17,5.45-6.35,9.873-11.569,9.877H431.137Z" transform="translate(-0.095)" fill="url(#lineargradient2)"></path>
                    </g>
                </svg></a>
                <div className="chat__dialog-header-status">
        {/*<span className={classNames('status', { 'status--online': online })}>*/}
        {/*  {online ? 'онлайн' : 'офлайн'}*/}
        {/*</span>*/}
                </div>
            </div>
            {currentConversation != -1 ? (
                <div>
                    <Popover
                        className="chat__dialog-header-action"
                        content={
                            <div>
                                <Button onClick={() => deleteConversation()}>Șterge conversatia</Button>
                            </div>
                        }
                        trigger="click">
                        <div>
                            <Button type="link" shape="circle" icon={<EllipsisOutlined />} />
                        </div>
                    </Popover>
                    {window.innerWidth < 1140 ?
                        (<Button className="chat__dialog-header-action" onClick={() => goBack()} style={{left: 20,transform: "rotate(0deg)"}} shape="circle" icon={<ArrowLeftOutlined />} />)
                        : ('')
                    }
                </div>

            ) : ('')

            }

        </div>
    );
}

Status.propTypes = {
    online: PropTypes.bool,
};

export default Status;