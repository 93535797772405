import React, {useEffect, useState} from 'react';
import { Messages, ChatInput, Status, Sidebar } from "../../components";
import './Home.scss';
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import {
    getAuthUser,
    getOrganisationConversationsAction,
    getUserConversationsAction,
    setIsApplicantAction
} from "../../redux/actions/user";
import socket from "../../utils/socketio";
import {sendMessage} from "../../redux/reducers/user";



export const Home = ({isApplicant,match}:any) => {
    const dispatch = useAppDispatch()
    const user:any = useAppSelector((state) => state.users.user)
    const currentConversation = useAppSelector(state =>  state.users.currentConversation != null ? state.users.currentConversation : -1)
    const items = useAppSelector(state => state.users.conversations)
    const [searchState, setSearchState] = useState([]);
    const conversations = useAppSelector(state => state.users.conversations)
    useEffect(() => {
        dispatch(getAuthUser())
        dispatch(setIsApplicantAction(isApplicant))
        isApplicant ? dispatch(getUserConversationsAction()) : dispatch(getOrganisationConversationsAction())
        setSearchState(conversations);
        socket.on('clientSendMessage',(message) => {
            if(!localStorage.getItem(`unreadMessageCount${message.application_id}`)){
                localStorage.setItem(`unreadMessageCount${message.application_id}`,"0")
            }
            console.log(message)
            // @ts-ignore
            if(message.user_id.toString() !== localStorage.getItem('user_id').toString()){

                 // @ts-ignore
                localStorage.setItem(`unreadMessageCount${message.application_id}`, `${parseInt(localStorage.getItem(`unreadMessageCount${message.application_id}`)) + 1}`)
                console.log(message)
            }


            dispatch(sendMessage(message))
        })
    },[]);

    return (
        <section className="home">
            <div className="chat">
                <Sidebar items={items} />
                <div className="chat__dialog">
                    <Status />
                    {(user && currentConversation != -1) ? (
                        <>
                            <Messages />
                            <div className="chat__dialog-input">
                                <ChatInput />
                            </div>
                        </>

                        ):
                        (
                                <Messages />
                        )}
                </div>
                </div>
        </section>
    );
};


