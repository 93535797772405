import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

const Time = ({date}:any) => (
  <Fragment>
    <span>{moment(date).fromNow()}</span>
  </Fragment>
);

Time.propTypes = {
  date: PropTypes.string
};

export default Time;
