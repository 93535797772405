import {createSlice} from '@reduxjs/toolkit'
import socket from "../../utils/socketio";


// Define the initial state using that type
const user = createSlice({
    name: 'user',
    initialState: {
        currentConversation: null,
        currentConversationId: null,
        isApplicant: null,
        user: [],
        users: [],
        conversations : [],
        messageEditMode: false,
        editedMessageId: null,
        editMessageText: null,
        isUploadFileMessage: false,
        uploadedFilesCount: 16
    },
    reducers: {
        setUploadedFiles(state, action){
            state.uploadedFilesCount = action.payload
        },
        getAllUsers(state, action) {
            state.users = action.payload
        },
        getUser(state, action){
            state.user = action.payload
        },
        getUserConversations(state, action){
            state.conversations = action.payload
        },
        getUserConversationMessages(state, action){
            const {id, result} = action.payload;
            // @ts-ignore
            state.conversations[id].messages = result ? result : []

        },
        setIsApplicant(state, action){
            state.isApplicant = action.payload
        },
        sendMessage(state, action){
        // @ts-ignore
            if(state.currentConversation != null)
                // @ts-ignore
                state.conversations[state.currentConversation].messages.push(action.payload)
        },
        setCurrentConversation(state,action) {
            state.currentConversationId = action.payload
            state.currentConversation = action.payload
        },

        setCurrentConversationId(state,action){
            const {id, key} = action.payload
            state.currentConversationId = id
            state.currentConversation = key
            socket.emit('join', {conversation: id})
        },
        deleteConversation(state, action){
            // @ts-ignore
            state.conversations = state.conversations.filter((item) => {return item.id != state.currentConversationId});
            state.currentConversation = null
            state.currentConversationId = null
        },
        deleteMessage(state, action){
            // @ts-ignore
            state.conversations[state.currentConversation].messages = state.conversations[state.currentConversation].messages
                                                                            .filter((item:any) => {return item.id != action.payload})
        },
        editMessage(state, action){
            // @ts-ignore
            let index = state.conversations[state.currentConversation].messages.findIndex(message => message.id === action.payload.id)
            // @ts-ignore
            state.conversations[state.currentConversation].messages[index].message = action.payload.text
        },
        messageEditMode(state, action){
            state.messageEditMode = action.payload.state
            state.editedMessageId = action.payload.id

            // @ts-ignore
            let index = state.conversations[state.currentConversation].messages.findIndex(message => message.id === action.payload.id)
            // @ts-ignore
            state.editMessageText = state.conversations[state.currentConversation].messages[index].message
        },
        setIsUploadFileMessage(state,action){
            state.isUploadFileMessage = action.payload
        },
        searchDialogs(state,action){
            let conversations = state.conversations.filter((val:any) => val.organisation_name.includes(action.payload))
            return {
                ...state,
                conversations
            }
        }
    },
});

export const {
    getAllUsers,
    getUser,
    getUserConversations,
    getUserConversationMessages,
    setIsApplicant,
    sendMessage,
    setCurrentConversationId,
    deleteConversation,
    deleteMessage,
    editMessage,
    messageEditMode,
    setIsUploadFileMessage,
    searchDialogs,
    setUploadedFiles,
    setCurrentConversation
} = user.actions
export default user.reducer
