import React from "react";
import PropTypes from "prop-types";
import readedSvg from "./../../assets/img/readed.svg";
import noReadedSvg from "./../../assets/img/noreaded.svg";

const IconReaded = ({ isMe, isReaded }:any) =>
  (isMe &&
    (isReaded ? (
      <img src={readedSvg} className="message__icon-readed" alt="Readed icon" />
    ) : (
      <img
          src={noReadedSvg}
        className="message__icon-readed message__icon-readed--no"
        alt="No readed icon"
      />
    ))) ||
  null;

export default IconReaded;
