import React from 'react';



import './MessageAudio.scss';
//{ audioSrc }

const MessageAudio = () => {

    return (
        <div className="message__audio">

        </div>
    );
};

MessageAudio.defaultProps = {
    user: {},
};

MessageAudio.propTypes = {

};

export default MessageAudio;
