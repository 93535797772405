import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
//
// import { dialogsActions } from 'redux/actions';
// import socket from 'core/socket';
import moment from "moment"
import { Dialogs as BaseDialogs } from "../../components";
import {useAppDispatch, useAppSelector} from "../../utils/hook";


const Dialogs = ({items}:any) => {

  return (
      <div className="dialogs">
        {items ? items.map((item:any, key:any) => {
          return (<BaseDialogs index={key} item={item} />)
            })
            : ''}
      </div>
  );
};

export default Dialogs;