import { useEffect, useState } from "react";
import axios from "../utils/axios";
import {useAppDispatch, useAppSelector} from "./hook";
import {sendMessageAction} from "../redux/actions/user";

const useRecorder = () => {
    const dispatch = useAppDispatch();
    const [audioURL, setAudioURL] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [chunks]:any = useState([])
    const user:any = useAppSelector(state => state.users.user)
    const conversationId:any = useAppSelector(state => state.users.currentConversationId)
    useEffect(() => {
        // Lazily obtain recorder first time we're recording.
        if (recorder === null) {
            if (isRecording) {
                // @ts-ignore
                requestRecorder().then(setRecorder, console.error);
            }
            return;
        }

        // Manage recorder state.
        if (isRecording) {
            // @ts-ignore
            recorder.start();
        } else {
            // @ts-ignore
            recorder.stop();
        }

        // Obtain the audio when ready.
        // @ts-ignore
        const handleData = e => {
            setAudioURL(URL.createObjectURL(e.data));
            let formData = new FormData();
            formData.append('message', e.data);
            formData.append('application_id',conversationId);
            formData.append('user_id',user.id);
            formData.append('is_audio',"true")
            dispatch(sendMessageAction({message: '',is_audio:true,formData: formData}))
        };

        // @ts-ignore
        recorder.addEventListener("dataavailable", handleData);
        // @ts-ignore
        return () => recorder.removeEventListener("dataavailable", handleData);
    }, [recorder, isRecording]);

    const startRecording = () => {
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
        console.log("recorder stopped");
    };

    return [audioURL, isRecording, startRecording, stopRecording];
};

async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    return new MediaRecorder(stream);
}
export default useRecorder;