import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Popover, Button, Avatar} from 'antd';


import waveSvg from './../../assets/img/wave.svg';
import playSvg from './../../assets/img/play.svg';
import pauseSvg from './../../assets/img/pause.svg';

import { IconReaded } from '../';
import './Message.scss';
import Icon, {EllipsisOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import Time from "../Time";
import {
    deleteMessageAction,
    messageEditModeAction
} from "../../redux/actions/user";
import convertCurrentAudioTime from "../../utils/convertCurrentAudioTime";
import UploadFiles from "../UploadFiles";

const {REACT_APP_API_HOST,REACT_APP_API_PORT} = process.env
const MessageAudio = ({ audioSrc }:any) => {
    const audioElem = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const togglePlay = () => {
        if (!isPlaying) {
            // @ts-ignore
            audioElem.current.play();
        } else {
            // @ts-ignore
            audioElem.current.pause();
        }
    };

    useEffect(() => {

        // @ts-ignore
        audioElem.current.volume = '1';
        // @ts-ignore
        audioElem.current.addEventListener(
            'playing',
            () => {
                setIsPlaying(true);
            },
            false,
        );
        // @ts-ignore
        audioElem.current.addEventListener(
            'ended',
            () => {
                setIsPlaying(false);
                setProgress(0);
                setCurrentTime(0);
            },
            false,
        );
        // @ts-ignore
        audioElem.current.addEventListener(
            'pause',
            () => {
                setIsPlaying(false);
            },
            false,
        );
        // @ts-ignore
        audioElem.current.addEventListener('timeupdate', () => {
            // @ts-ignore
            const duration = (audioElem.current && audioElem.current.duration) || 0;
            // @ts-ignore
            setCurrentTime(audioElem.current.currentTime);
            // @ts-ignore
            setProgress((audioElem.current.currentTime / duration) * 100);
        });
    }, []);

    return (
        <div className="message__audio">
            <audio ref={audioElem}  preload="auto">
                <source src={audioSrc} type="audio/webm" />
            </audio>
            <div className="message__audio-progress" style={{ width: progress + '%' }} />
            <div className="message__audio-info">
                <div className="message__audio-btn">
                    <button onClick={togglePlay}>
                        {isPlaying ? (
                            <img src={pauseSvg} alt="Pause svg" />
                        ) : (
                            <img src={playSvg} alt="Play svg" />
                        )}
                    </button>
                </div>
                <div className="message__audio-wave">
                    <img src={waveSvg} alt="Wave svg" />
                </div>
                <span className="message__audio-duration">{convertCurrentAudioTime(currentTime)}</span>
            </div>
        </div>
    );
};

const Message = ({
                     attachments,
                     isTyping,
                     setPreviewImage,
                     message
                 }:any) => {
    const isAplicant:any = useAppSelector(state => state.users.isApplicant)
    const dispatch = useAppDispatch();
    const user:any = useAppSelector(state => state.users.user)
    const conversation:any = useAppSelector(state => state.users.conversations[state?.users?.currentConversation!])
    const [messageText, setMessageText] = useState(message.message)
    const [popoverVisible,setPopoverVisible] = useState(false)
    const {REACT_APP_SITE_HOME_LINK, REACT_APP_API_HOST} = process.env;
    const onRemoveMessage = (id:any) => {
        dispatch(deleteMessageAction(id))
        setPopoverVisible(false)
    }
    const onEditMessage = (id:any) => {
        dispatch(messageEditModeAction(id,true))
        setPopoverVisible(false)
    }

    const onEditText = (e:any) => {
        setMessageText(e.target.value)
    }

    const togglePopover = () => {
        setPopoverVisible(!popoverVisible)
    }

    return (
        <div
            className={classNames('message', {
                'message--isme': message.user_id === user.id,
                'message--is-typing': false,
                'message--is-audio': true,
                'message--image': true,
            })}>
            <div className="message__content">
                <IconReaded isMe={message.user_id === user.id} isReaded={message.is_readed} />
                <div>
                    <div className="message__avatar">
                    {isAplicant ? (
                        message.user_id === user.id ? (
                            <Avatar src={`${REACT_APP_API_HOST}/storage/cvavatars/` + (conversation.cv_avatar != null ? conversation.cv_avatar : "default.jpg")} style={{margin:'10px 10px'}} />
                        ) : (
                            <Avatar src={`${REACT_APP_API_HOST}/storage/organisationavatars/` + (conversation.org_avatar != null ? conversation.org_avatar : "default.jpg")} style={{margin:'10px 10px'}} />
                        )
                    ) : (
                        message.user_id === user.id ? (
                            <Avatar src={`${REACT_APP_API_HOST}/storage/organisationavatars/` + (conversation.org_avatar != null ? conversation.org_avatar : "default.jpg")} style={{margin:'10px 10px'}} />
                        ) : (
                            <Avatar src={`${REACT_APP_API_HOST}/storage/cvavatars/` + (conversation.cv_avatar != null ? conversation.cv_avatar : "default.jpg")} style={{margin:'10px 10px'}} />
                        )
                    )}
                    </div>
                <Popover
                    content={
                        <div>
                            <Button onClick={() => onRemoveMessage(message.id)}>Șterge mesajul</Button>
                            {!message.is_audio && (
                                <Button onClick={() => onEditMessage(message.id)}>Editează mesajul</Button>
                            )}
                        </div>
                    }
                    trigger="click" onVisibleChange={togglePopover} visible={popoverVisible}>
                    <div style={{display: "flex",justifyContent: "center"}}>
                        <Button style={{transform: "rotate(90deg)"}} type="link" shape="circle" icon={<EllipsisOutlined />} />
                    </div>
                </Popover>
                </div>
                <div className="message__info">
                    { !message.is_audio ? (
                        <div className="message__bubble">
                            { message.message && (
                                <p className="message__text">
                                    {message.message}
                                    {message.files ? message.files.map((file:any) => {
                                        return (
                                            <UploadFiles link={REACT_APP_API_HOST + '/storage' + file.filename.replace('public','')} type={file.filename.substr(file.filename.lastIndexOf('.') + 1)} name={file.filename.substr(file.filename.split(".").lastIndexOf('/') + 21)} />
                                        )
                                    }) : ('')}

                                </p>
                            )}

                        </div>
                    ): (
                        <MessageAudio audioSrc={`${REACT_APP_API_HOST}/storage` + message.message.replace('public','')} />
                    )}

                        <span className="message__date">
                            <Time date={message.created_at} />
                        </span>
                </div>
            </div>
        </div>
    );
};

Message.defaultProps = {
    user: {},
};

Message.propTypes = {
    isReaded: PropTypes.bool,
    message: PropTypes.any
};

export default Message