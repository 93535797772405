import React, {useEffect, useRef} from 'react';


import './Messages.scss';
import IconReaded from "../IconReaded";
import {Avatar, Button, Empty, Modal, Popover, Spin} from "antd";
import {Message, MessageAudio} from "../index";
import Time from "../Time";
import {CheckCircleTwoTone, EllipsisOutlined} from "@ant-design/icons";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import {previewImage} from "antd/es/upload/utils";
import {getUserConversationMessagesAction} from "../../redux/actions/user";
import socket from "../../utils/socketio";

const Messages = () => {
    const messageList = useRef(null);
    const dispatch = useAppDispatch();
    const currentConversation = useAppSelector(state => state.users.currentConversation != null ? state.users.currentConversation : -1)
    const conversation:any = useAppSelector(state => state.users.conversations[currentConversation != -1 ? currentConversation : -1])
  const isTyping = true;
  let attachments: any[];
  attachments = [];
  const isLoading = true;
  const partner = {}
  let date:string;

  const user = useAppSelector(state => state.users.user);
    let blockHeight:any = useAppSelector(state => state.users.uploadedFilesCount)

  date = '';
  useEffect(() => {
      console.log(conversation)
      // @ts-ignore
      messageList.current.scrollTo(
              {
                  behavior: 'smooth',
                  // @ts-ignore
                  top: 10000000000000000000000000000
              })

  })


  const onRemoveMessage = () => {

  }

  const renderAttachment = (attachment:any) => {

  }

    function setPreviewImage(param:any) {

    }

    //style={{ height: `calc(100% - ${blockHeight}px)` }}
  return (
      <div className="chat__dialog-messages" style={{ height: `calc(${window.innerHeight - blockHeight - 150}px)` }}>
          <div className={classNames('messages', { 'messages--loading': false })} ref={messageList}>
              {!isLoading ? (
                  <Spin size="large" tip="Loading" />
              ) : currentConversation != -1 ? (
                  conversation && conversation.messages.length  ? (
                      conversation.messages.map((item:any, key:any) => (
                          <Message
                              key={key}
                              isReaded={true}
                              message={item}
                          />
                       ))
                  ) : (
                      <Empty description="Nu sunt message" />
                  )
              ) : (
                  <Empty description="Deschideți conversația" />
              )}
          </div>
      </div>
  );
};

// Messages.propTypes = {
//   items: PropTypes.array,
// };

export default Messages;
