import React, {useEffect, useState} from 'react';

import './Sidebar.scss';
import {FormOutlined, TeamOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {Dialogs} from "../../containers";
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import Search from "antd/es/input/Search";
import {searchDialogAction} from "../../redux/actions/user";
//
// const { Option } = Select;
// const { TextArea } = Input;

const Sidebar = ({items}:any) => {
    const dispatch = useAppDispatch();
    const conversations = useAppSelector(state => state.users.conversations)
    const user = useAppSelector(state => state.users.user)
    const [searchState, setSearchState] = useState([]);
    useEffect(() => {
        setSearchState(items);
        // @ts-ignore
        localStorage.setItem('user_id',user.id)
    },[items])
    const search = (event:any) => {
        let searchedConversations = items.filter((val:any) => val.organisation_name.includes(event.target.value) || val.position.includes(event.target.value) || val.name.includes(event.target.value))
        // @ts-ignore
        console.log(searchedConversations);

        // @ts-ignore
        setSearchState(searchedConversations)
    }
  return (
    <div className="chat__sidebar">
      <div style={{display: "flex",flexDirection:'column', alignItems:"start"}} className="chat__sidebar-header">
        <div style={{justifyContent:"center" }}>
            <TeamOutlined />
          <span style={{fontSize: "1.4rem", }}>Chat</span>
        </div>
          <Search placeholder={"Căutare"} onChange={(event) => search(event)} onPressEnter={(event) => search(event)}/>
      </div>
        <div className="chat__sidebar-dialogs">
            <Dialogs items={searchState} conversations={searchState} />
        </div>
    </div>
  );
};

// Sidebar.defaultProps = {
//   users: [],
// };
export default Sidebar;
