import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import {store} from './redux/store'

import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import socket from "./utils/socketio";
import {sendMessage} from "./redux/reducers/user";
import {useAppDispatch, useAppSelector} from "./utils/hook";
import queryString from "query-string";
require('dotenv').config();
const location = window.location;
const token = queryString.parse(location.search).token

// @ts-ignore
if (typeof token === "string" && token.length > 0) {
    localStorage.setItem('token', token)
    // @ts-ignore
    window.location.search = []
}
// window.addEventListener('beforeunload', function (e) {
//     e.preventDefault();
//     localStorage.removeItem('token')
// });

// socket.on('clientSendMessage',(message) => {
//     // @ts-ignore
//     // if(message.user_id.toString() !== localStorage.getItem('user_id').toString()){
//     //     // @ts-ignore
//     //     localStorage.setItem('unreadMessageCount', `${parseInt(localStorage.getItem('unreadMessageCount')) + 1}`)
//     //     console.log(message)
//     // }
//
// })
ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
