import React from 'react';
import './App.css';
import { Route, Switch} from "react-router-dom";
import {Home,Error} from "./pages";
function App() {

    return (
        <div className="wrapper">
            <Switch>
                <Route
                    path="/organisation"
                    render={() => <Home isApplicant={false}/>}
                />
                <Route
                    path="/applicant"
                    render={() => <Home isApplicant={true}/>}
                />
                <Route
                    path="/organisation/:token"
                    render={() => <Home isApplicant={false}/>}
                />
                <Route
                    path="/applicant/:token"
                    render={() => <Home isApplicant={true}/>}
                />
                <Route
                    exact
                    path={["*"]}
                    component={Error}
                />
            </Switch>
        </div>
    );


}

export default App;
