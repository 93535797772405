import React, {Fragment, useEffect, useRef, useState} from "react";
import "./ChatInput.scss";
import Picker  from 'emoji-picker-react';
import {message, Button, Input, Upload, Popover} from 'antd';
import {
  AudioOutlined,
  CaretRightFilled, CloseCircleTwoTone,
  SmileOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../utils/hook";
import {
  editMessageAction,
  messageEditModeAction,
  sendMessageAction,
  setIsUploadFileMessageAction, setMessagesIsReaded, setUploadedFilesAction
} from "../../redux/actions/user";
import useRecorder from "../../utils/useRecorder";
import TextArea from "antd/es/input/TextArea";
import {setIsUploadFileMessage} from "../../redux/reducers/user";
const ChatInput = () => {
  const [textMessage, setTextMessage]:any = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const editMessageId = useAppSelector(state => state.users.editedMessageId)
  const [files, setFiles]:any = useState([]);
  const [fileList, setFileList]:any = useState([]);
  // @ts-ignore
  const editMessageText = useAppSelector(state => state.users.editMessageText)
  const isUploadFileMessage = useAppSelector(state => state.users.isUploadFileMessage)
  let blockHeagth = useAppSelector(state => state.users.uploadedFilesCount);
  const dispatch = useAppDispatch();
  const user:any = useAppSelector(state => state.users.user)
  const conversationId:any = useAppSelector(state => state.users.currentConversationId)
  const currentConversation:any = useAppSelector(state => state.users.currentConversation)
  const props = {
    name: 'file',
    multiple: true,
    customRequest: ({onSuccess}:any) => {
      onSuccess("ok")
    },
    onChange(info:any) {
      if (info.file.status === 'done') {
        setFiles([...files,info.file.originFileObj])
        message.success(`${info.file.name} file uploaded successfully`);
        dispatch(setIsUploadFileMessageAction(true))
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }

      let fileList = [...info.fileList];


      // 2. Read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      dispatch(setUploadedFilesAction(fileList.length + blockHeagth + 15))
      setFileList( fileList );

    },
  };

  const emojiInput = useRef(null);
  let [audioURL, isRecording, startRecording, stopRecording]:any = useRecorder();





  const onEmojiClick = (event:any, emojiObject:any) => {
    // @ts-ignore
    const cursor = cursorPosition;
    console.log(event.target.selectionStart)
     // @ts-ignore
    const text = textMessage.slice(0, cursor) + emojiObject.emoji + textMessage.slice(cursor);
     setTextMessage(text)
    // @ts-ignore
    setCursorPosition(cursorPosition + emojiObject.emoji.length)
  };
  const editMode = useAppSelector(state => state.users.messageEditMode)

  const editMessage = (id:any, text:any) => {
    dispatch(editMessageAction(id,text))
    dispatch(messageEditModeAction(id,false))
  }

  const inputOnChange = (e:any) => {
    // @ts-ignore
    setTextMessage(textMessage => [e.target.value])
  }

  const sendTextMessage = () => {

    if(editMode){
      editMessage(editMessageId,textMessage !== null ? textMessage : '.')
    }else{
      //{message:'', is_audio:boolean = false, formData:any = null,is_file:boolean = false}
      if(isUploadFileMessage){
        console.log(files)
        let formData = new FormData();
        formData.append('message', textMessage !== null ? textMessage : '.');
        formData.append('application_id',conversationId);
        formData.append('user_id',user.id);
        formData.append('is_file',"true")
        files.forEach((file:any) => {
          formData.append('files[]',file)
        })

        dispatch(sendMessageAction({is_file: true, formData: formData}))
        setFileList([])
        setFiles([])
        dispatch(setUploadedFilesAction(13))
        dispatch(setIsUploadFileMessageAction(false));
      }else{
        if(textMessage !== '' && textMessage !== null)
          dispatch(sendMessageAction({message:textMessage}))
      }

    }
    localStorage.setItem(`unreadMessageCount${conversationId}`, '0')
    setTextMessage(null);
  }

  useEffect(() => {
    // @ts-ignore
    setTextMessage(textMessage =>editMessageText ? editMessageText.toString() : null)
  },[editMessageText])
  return (
    <Fragment>
      <div style={{display: 'flex', alignItems: 'center'}} className="chat-input">
        <Popover
            content={
              <div>
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            }
            trigger="click">
          <Button icon={ <SmileOutlined />} shape={"circle"} type={"link"}  />
        </Popover>

        {isRecording ? (
            <div className="chat-input__record-status">
              <i className="chat-input__record-status-bubble"></i>
              Recording...
              <Button
                  onClick={stopRecording}
                  type="link"
                  shape="circle"
                  icon={<CloseCircleTwoTone />}
                  className="stop-recording"
              />
            </div>
        ) : (
            <TextArea onBlur={(e:any) => setCursorPosition(e.target.selectionStart)} ref={emojiInput} value={textMessage} onChange={inputOnChange} onPressEnter={() => sendTextMessage()} placeholder="Scrie aici mesajul" />
        )}


        <Button onClick={() => sendTextMessage()} className={"sendButton"} icon={<CaretRightFilled/>}>
        </Button>
        <Upload {...props} fileList={fileList}>
          <Button shape={"circle"} type={"link"} icon={<UploadOutlined />}></Button>
        </Upload>
        <Button onClickCapture={startRecording} icon={<AudioOutlined/>} shape={"circle"} type={"link"}  />
      </div>
    </Fragment>
  );
};

// ChatInput.propTypes = {
//   className: PropTypes.string
// };

export default ChatInput;
